export const env = {
  kenticoAssetURL: process.env.kenticoAssetURL,
  clientId: process.env.clientId,
  clientSecret: process.env.clientSecret,
  credstoreKongUtilsServiceBasicAuthToken: process.env.credstoreKongUtilsServiceBasicAuthToken,
  hcidExtrnalApiBaseUrl: process.env.hcidExtrnalApiBaseUrl,
  kongBasicAuthToken: process.env.kongBasicAuthToken,
  credstoreKongUtilsServiceProvisionKey:
    process.env.credstoreKongUtilsServiceProvisionKey,
  baseUrl: process.env.baseUrl
}
