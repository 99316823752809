export const moneyFormat = (val: string) => {
  return val.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const formatRupiah = (money: number, nonCurrency?: boolean) => {
  return new Intl.NumberFormat("id-ID", {
    style: nonCurrency ? undefined : "currency",
    currency: "IDR",
    minimumFractionDigits: 0
  })
    .format(money)
    .replace(/\s+/g, "");
};


export const reformatRupiah = (money: string) => {
  const number = money.replace(/\D/g, "");
  return parseInt(number);
};
